// 编辑 -- 新增
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">专项考试</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{ title }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box" style="width: 80%; margin-left: 15px">
              <el-form
                ref="ruleForm"
                label-width="8rem"
                class=""
                :rules="rules"
                :model="ruleForm"
                :disabled="examStu != '10'"
              >
                <el-form-item label-width="7rem">
                  <h3 slot="label" class="label_title">基本信息</h3>
                </el-form-item>
                <el-form-item label="考试名称:" prop="examName">
                  <el-input
                    v-model="ruleForm.examName"
                    maxlength="30"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="行政区划:" prop="areaId">
                  <el-cascader
                    :props="{ emitPath: false, checkStrictly: true }"
                    v-model="ruleForm.areaId"
                    :options="cityTree"
                    @change="handleCascader"
                    clearable
                    filterable
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="考试类型:" prop="Trainingtype">
                  <tree
                    ref="tree"
                    @eventBtn="childBack"
                    :ruleForm="ruleForm"
                    @clearParams="clearParams"
                    modal
                    type
                    typeStu
                  />
                </el-form-item>
                <el-form-item label="考试时间:" prop="time">
                  <el-date-picker
                    v-model="ruleForm.time"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="考试说明:">
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="10"
                    v-model="ruleForm.examDescription"
                  ></el-input>
                </el-form-item>
                <el-form-item label-width="7rem">
                  <h3 slot="label" class="label_title">考试设置</h3>
                </el-form-item>
                <el-form-item label="答题中查看解析:" prop="middleParse">
                  <el-switch
                    v-model="ruleForm.middleParse"
                    :active-value="'10'"
                    :inactive-value="'20'"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="交卷后查看解析:" prop="afterParse">
                  <el-switch
                    v-model="ruleForm.afterParse"
                    :active-value="'10'"
                    :inactive-value="'20'"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="考试前实名认证:" prop="examAuthentication">
                  <el-switch
                    v-model="ruleForm.examAuthentication"
                    :active-value="'10'"
                    :inactive-value="'20'"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="参加学员:" prop="publicState">
                  <el-radio-group v-model="ruleForm.publicState">
                    <el-radio label="10" style="width: 84px">无限制</el-radio>
                    <el-radio label="20">指定学员</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="答题次数:" prop="answerNum">
                  <div class="flexca">
                    <el-radio-group v-model="ruleForm.answerNum">
                      <el-radio label="20" style="width: 84px">无限次</el-radio>
                      <el-radio label="10">限制次数</el-radio>
                    </el-radio-group>
                    <el-input-number
                      style="width: 200px; margin-left: 10px"
                      placeholder="输入答题次数"
                      :disabled="ruleForm.answerNum == '20'"
                      :min="1"
                      :precision="0"
                      size="small"
                      v-model="answerNumValue"
                    ></el-input-number>
                  </div>
                </el-form-item>
                <el-form-item label="答卷时间:" prop="handoverTime">
                  <div class="flexca">
                    <el-radio-group v-model="ruleForm.handoverTime">
                      <el-radio label="20" style="width: 84px"
                        >不限时长</el-radio
                      >
                      <el-radio label="10">限制时长</el-radio>
                    </el-radio-group>
                    <el-input-number
                      style="width: 200px; margin-left: 10px"
                      placeholder="输入时长"
                      size="small"
                      :disabled="ruleForm.handoverTime == '20'"
                      v-model="handoverTimeValue"
                      :precision="0"
                      :min="1"
                    ></el-input-number>
                    <span>分钟</span>
                  </div>
                </el-form-item>
                <el-form-item label-width="7rem">
                  <h3 slot="label" class="label_title">考试试卷</h3>
                </el-form-item>
                <el-form-item label="试卷形式:" prop="examMode">
                  <el-radio-group v-model="ruleForm.examMode" disabled>
                    <el-radio label="10">指定试卷</el-radio>
                    <el-radio label="20">随机抽题</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="指定试卷:"
                  v-if="ruleForm.examMode == '1'"
                  prop=""
                >
                  <el-select
                    v-model="ruleForm.region"
                    placeholder="请选择活动区域"
                  >
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="指定题库:" v-else prop="questionBankId">
                  <el-select
                    v-model="ruleForm.relevanceId"
                    placeholder="请选择题库"
                    @change="questionbankChange"
                    filterable
                    remote
                    :remote-method="getquestionBank"
                  >
                    <el-option
                      :label="item.questionBankName"
                      :value="item.questionBankId"
                      v-for="(item, index) in questionbankList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="ruleForm.examMode != '1'" prop="paperObj">
                  <div>
                    <p>
                      在
                      <span
                        style="
                          display: inline-block;
                          width: 50px;
                          text-align: center;
                        "
                        >{{ choiceNum }}</span
                      >道单选题中随机抽
                      <el-input-number
                        v-model="ruleForm.paperObj.SinglechoiceNum"
                        size="small"
                        :precision="0"
                        :min="0"
                        :max="choiceNumMax"
                        :disabled="!ruleForm.relevanceId"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >道题目，每题
                      <el-input-number
                        v-model="ruleForm.paperObj.SinglechoiceScore"
                        size="small"
                        :min="0"
                        :disabled="!ruleForm.relevanceId"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >分
                    </p>
                    <p>
                      在
                      <span
                        style="
                          display: inline-block;
                          width: 50px;
                          text-align: center;
                        "
                      >
                        {{ multiselectNum }} </span
                      >道多选题中随机抽
                      <el-input-number
                        v-model="ruleForm.paperObj.MultiplechoiceNum"
                        size="small"
                        :min="0"
                        :precision="0"
                        :max="multiselectNumMax"
                        :disabled="!ruleForm.relevanceId"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >道题目，每题
                      <el-input-number
                        v-model="ruleForm.paperObj.MultiplechoiceScore"
                        size="small"
                        :min="0"
                        :disabled="!ruleForm.relevanceId"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >分
                    </p>
                    <p>
                      在
                      <span
                        style="
                          display: inline-block;
                          width: 50px;
                          text-align: center;
                        "
                      >
                        {{ judgeNum }} </span
                      >道判断题中随机抽
                      <el-input-number
                        v-model="ruleForm.paperObj.judgeNum"
                        size="small"
                        :min="0"
                        :precision="0"
                        :max="judgeNumMax"
                        :disabled="!ruleForm.relevanceId"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >道题目，每题
                      <el-input-number
                        v-model="ruleForm.paperObj.judgeScore"
                        size="small"
                        :min="0"
                        :disabled="!ruleForm.relevanceId"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >分
                    </p>
                    <p>
                      在
                      <span
                        style="
                          display: inline-block;
                          width: 50px;
                          text-align: center;
                        "
                      >
                        {{ completionNum }} </span
                      >道填空题中随机抽
                      <el-input-number
                        v-model="ruleForm.paperObj.fillNum"
                        size="small"
                        :min="0"
                        :precision="0"
                        :max="completionNumMax"
                        :disabled="!ruleForm.relevanceId"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >道题目，每题
                      <el-input-number
                        v-model="ruleForm.paperObj.fillScore"
                        size="small"
                        :min="0"
                        :disabled="!ruleForm.relevanceId"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >分
                    </p>
                  </div>
                </el-form-item>
                <el-form-item label="试卷总分:" prop="totalScore" required>
                  <div class="">
                    <el-input
                      v-model="ruleForm.totalScore"
                      disabled
                      style="width: 200px; margin-right: 10px"
                    ></el-input
                    ><span>分</span>
                  </div>
                </el-form-item>
                <el-form-item label="及格分数:" prop="passScore">
                  <div class="">
                    <el-input-number
                      v-model="ruleForm.passScore"
                      :min="0"
                      :precision="0"
                      style="width: 200px; margin-right: 10px"
                    ></el-input-number>
                    <!-- <el-input
                      v-model="ruleForm.passScore"
                      style="width: 200px; margin-right: 10px"
                    ></el-input> -->
                    <span>分</span>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="btn-box flexcc" style="margin-bottom: 10px">
              <el-button @click="dohandleOk" class="bgc-bv">取 消</el-button>
              <el-button
                :disabled="examStu != '10'"
                v-if="stu === 'add'"
                class="bgc-bv"
                @click="doAddSave"
                >确 定</el-button
              >
              <el-button
                :disabled="examStu != '10'"
                v-else
                class="bgc-bv"
                @click="doEditSave"
                >确 定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tree from "@/components/treePopup";
export default {
  name: "specialTestEdit",
  components: {
    tree,
  },
  watch: {
    // 监听抽题数量变化 把分数清零
    "ruleForm.paperObj.SinglechoiceNum": {
      handler: function (val) {
        console.log(val);
        if (val == 0) {
          this.ruleForm.paperObj.SinglechoiceScore = 0;
        }
        if (val == undefined) {
          this.ruleForm.paperObj.SinglechoiceNum = "0";
        }
      },
    },
    "ruleForm.paperObj.MultiplechoiceNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleForm.paperObj.MultiplechoiceScore = 0;
        }
        if (val == undefined) {
          this.ruleForm.paperObj.MultiplechoiceNum = "0";
        }
      },
    },
    "ruleForm.paperObj.judgeNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleForm.paperObj.judgeScore = 0;
        }
        if (val == undefined) {
          this.ruleForm.paperObj.judgeNum = "0";
        }
      },
    },
    "ruleForm.paperObj.fillNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleForm.paperObj.fillScore = 0;
        }
        if (val == undefined) {
          this.ruleForm.paperObj.fillNum = "0";
        }
      },
    },
    // 计算总分
    "ruleForm.paperObj": {
      handler: function (val) {
        let {
          SinglechoiceNum,
          SinglechoiceScore,
          MultiplechoiceNum,
          MultiplechoiceScore,
          judgeNum,
          judgeScore,
          fillNum,
          fillScore,
        } = this.ruleForm.paperObj;
        this.ruleForm.totalScore = (
          SinglechoiceNum * SinglechoiceScore +
          MultiplechoiceNum * MultiplechoiceScore +
          judgeNum * judgeScore +
          fillNum * fillScore
        ).toFixed(1);
      },
      deep: true,
    },
  },
  data() {
    let checkPaperObj = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      let {
        SinglechoiceNum,
        SinglechoiceScore,
        MultiplechoiceNum,
        MultiplechoiceScore,
        judgeNum,
        judgeScore,
        fillNum,
        fillScore,
      } = value;
      if (SinglechoiceNum + MultiplechoiceNum + judgeNum + fillNum == 0) {
        callback(new Error("请至少选择一项题型题目数量为有效值"));
      } else {
        if (SinglechoiceNum > 0 && SinglechoiceScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (MultiplechoiceNum > 0 && MultiplechoiceScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (judgeNum > 0 && judgeScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (fillNum > 0 && fillScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else {
          callback();
        }
      }
    };
    let passSorceCheck = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      console.log(value);
      if (value && !reg.test(value)) {
        callback(new Error("及格分数应为大于或等于0的整数"));
      } else if (Number(value) > this.ruleForm.totalScore) {
        callback(new Error("及格分数不能大于总分数"));
      } else if (value == undefined) {
        callback(new Error("请输入及格分数"));
      } else {
        callback();
      }
    };
    // let answerNumCheck = (rule, value, callback) => {

    // };
    return {
      title: "新建考试",
      stu: "add",
      id: "",
      // 基本信息
      ruleForm: {
        areaId: "", // 行政区划
        time: "", // 考试时间
        examName: "", // 考试名称
        examDescription: "", //考试说明
        examMode: "20", // 试卷形式
        publicState: "10", //  参加学员
        handoverTime: "20", // 答题时间
        answerNum: "20", // 答题次数
        Trainingtype: "", // 培训类型
        middleParse: "20", //答题中查看解析
        afterParse: "10", // 交卷后查看解析
        examAuthentication: "10", // 考试前实名认证
        examAuthenticationCopy: "10", // 考试前实名认证 拷贝
        passScore: "", // 及格分数
        totalScore: "", // 总分数
        relevanceId: "", // 指定题库
        // 随机抽题时各题型的参数
        paperObj: {
          SinglechoiceNum: "0",
          SinglechoiceScore: 0,
          MultiplechoiceNum: "0",
          MultiplechoiceScore: 0,
          judgeNum: "0",
          judgeScore: 0,
          fillNum: "0",
          fillScore: 0,
        },
      },
      rules: {
        examMode: [{ required: true, message: "请选择试卷形式" }],
        examName: [{ required: true, message: "请输入考试名称" }],
        areaId: [{ required: true, message: "请选择行政区划" }],
        Trainingtype: [{ required: true, message: "请选择培训类型" }],
        time: [{ required: true, message: "请选择考试时间" }],
        relevanceId: [{ required: true, message: "请选择指定题库" }],
        middleParse: [{ required: true }],
        afterParse: [{ required: true }],
        examAuthentication: [{ required: true }],
        answerNum: [{ required: true }],
        handoverTime: [{ required: true }],
        publicState: [{ required: true }],
        totalScore: [{ required: true, message: "请选择试卷或试题计算总分数" }],
        passScore: [{ required: true, validator: passSorceCheck }],
        paperObj: [
          {
            required: true,
            validator: checkPaperObj,
            trigger: ["blur", "change"],
          },
        ],
      },
      handoverTimeValue: 60,
      answerNumValue: 1,
      choiceNum: 0,
      judgeNum: 0,
      multiselectNum: 0,
      completionNum: 0,
      choiceNumMax: 100,
      judgeNumMax: 100,
      multiselectNumMax: 100,
      completionNumMax: 100,
      questionNumTotal: 0,
      params: {},
      cityTree: [], // 行政区划
      questionbankList: [],
      examId: "", // 考试ID
      examStu: "10", //考试状态
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    init() {
      this.getcity(); // 行政区划
      this.getquestionBank();
      this.stu = this.$route.query.stu;
      if (this.stu == "edit") {
        // 编辑
        this.title = "编辑考试";
        this.examId = this.$route.query.examId;
        this.examStu = this.$route.query.examStu;
        this.getDetail(this.examId);
      }
    },
    getParams() {
      let params = {};
      params.eduExam = {
        areaId: this.ruleForm.areaId, // 行政区划
        time: this.ruleForm.time, // 考试时间
        endDate: this.ruleForm.time[1],
        startDate: this.ruleForm.time[0],
        examName: this.ruleForm.examName, // 考试名称
        examDescription: this.ruleForm.examDescription.replace(
          /\n/g,
          "sunrealbr"
        ), //考试说明
        questionNum:
          this.ruleForm.paperObj.SinglechoiceNum +
          this.ruleForm.paperObj.MultiplechoiceNum +
          this.ruleForm.paperObj.judgeNum +
          this.ruleForm.paperObj.fillNum,
        examMode: this.ruleForm.examMode, // 试卷形式
        publicState: this.ruleForm.publicState, //  参加学员
        handoverTime: this.ruleForm.handoverTime, // 答题时间
        answerNum: this.ruleForm.answerNum, // 答题次数
        middleParse: this.ruleForm.middleParse, //答题中查看解析
        afterParse: this.ruleForm.afterParse, // 交卷后查看解析
        examAuthentication: this.ruleForm.examAuthentication, // 考试前实名认证
        passScore: this.ruleForm.passScore, // 及格分数
        totalScore: this.ruleForm.totalScore, // 总分数
        relevanceId: this.ruleForm.relevanceId, // 指定题库
      };
      if (this.params.trainFormId) {
        params.eduExam.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.eduExam.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.eduExam.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.eduExam.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.eduExam.industryId = this.params.industryFormId;
      }
      params.eduExamConfig = [
        {
          configKey: "MIDDLE_PARSE",
          configState: this.ruleForm.middleParse,
          configOther: "{}",
        },
        {
          configKey: "AFTER_PARSE",
          configState: this.ruleForm.afterParse,
          configOther: "{}",
        },
        {
          configKey: "EXAM_AUTHENTICATION",
          configState: this.ruleForm.examAuthentication,
          configOther: "{}",
        },
        {
          configKey: "ANSWER_NUM",
          configState: this.ruleForm.answerNum,
          configOther:
            this.ruleForm.answerNum == "10"
              ? JSON.stringify({ answerNumValue: this.answerNumValue })
              : "{}",
        },
        {
          // 20 传值
          configKey: "HANDOVER_TIME",
          configState: this.ruleForm.handoverTime,
          configOther:
            this.ruleForm.handoverTime == "10"
              ? JSON.stringify({
                  handoverTimeValue: this.handoverTimeValue,
                })
              : "{}",
        },
      ];
      params.eduExamQuestionConfigs = [
        {
          questionNum: this.ruleForm.paperObj.SinglechoiceNum,
          questionScore: this.ruleForm.paperObj.SinglechoiceScore,
          questionType: "0",
        },
        {
          questionNum: this.ruleForm.paperObj.MultiplechoiceNum,
          questionScore: this.ruleForm.paperObj.MultiplechoiceScore,
          questionType: "1",
        },
        {
          questionNum: this.ruleForm.paperObj.judgeNum,
          questionScore: this.ruleForm.paperObj.judgeScore,
          questionType: "2",
        },
        {
          questionNum: this.ruleForm.paperObj.fillNum,
          questionScore: this.ruleForm.paperObj.fillScore,
          questionType: "3",
        },
      ];
      if (this.stu == "edit") {
        params.eduExam.examId = this.examId;
      }
      return params;
    },

    /**
     * 新增
     */
    doAddSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = this.getParams();
          this.$post("/biz/exam/insert", params).then((res) => {
            if (this.stu == "add") {
              //判断弹出导入学员提示
              if (this.ruleForm.publicState == "20") {
                // 指定学员
                this.$confirm("考试创建成功，是否导入指定学员?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                })
                  .then(() => {
                    this.$router.push({
                      name: "specialTestList",
                      params: {
                        isExport: 1,
                        examId: res.data.examId,
                        exportTempStu: res.data.examAuthentication,
                      },
                    });
                  })
                  .catch(() => {
                    this.goList()
                  });
              } else {
                this.goList()
              }
            }
          });
        } else {
          this.$message({
            type: "warning",
            message: "请检查必填项",
          });
        }
      });
    },
    /**
     * 编辑
     */
    doEditSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (
            this.ruleForm.examAuthentication ==
            this.ruleForm.examAuthenticationCopy
          ) {
            this.editAjax();
          } else {
            this.$confirm(
              "你确定要修改是否需要实名认证，修改后将删除本场考试中导入的全部学员",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.editAjax();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消保存",
                });
              });
          }
        } else {
          this.$meesage({
            type: "warning",
            message: "请检查必填项",
          });
        }
      });
    },
    editAjax() {
      // 编辑调接口
      let params = this.getParams();
      this.$post("/biz/exam/modify", params).then((res) => {
        if (res.status == "0") {
          this.goList()
        }
      });
    },
    goList() {
      this.$router.push({
        path: "/web/specialTestList",
      });
    },
    /**
     * 编辑回显
     */
    getDetail(examId) {
      this.$post("/biz/exam/getInfo", { examId }).then((res) => {
        // ;
        if (res.status == "0") {
          res.data.eduExam.startDate = res.data.eduExam.startDate.replace(
            /\//g,
            "-"
          );
          res.data.eduExam.endDate = res.data.eduExam.endDate.replace(
            /\//g,
            "-"
          );
          // console.log(res.data.eduExam);

          this.ruleForm.areaId = res.data.eduExam.areaId; // 行政区划
          this.ruleForm.time = [
            res.data.eduExam.startDate,
            res.data.eduExam.endDate,
          ]; // 考试时间
          this.ruleForm.examName = res.data.eduExam.examName; // 考试名称
          this.ruleForm.examDescription = res.data.eduExam.examDescription.replace(
            /sunrealbr/g,
            "\n"
          ); //考试说明
          this.ruleForm.examMode = res.data.eduExam.examMode; // 试卷形式
          this.ruleForm.publicState = res.data.eduExam.publicState; //  参加学员
          // this.ruleForm.handoverTime = res.data.eduExam.handoverTime; // 答题时间
          // this.ruleForm.answerNum = res.data.eduExam.answerNum; // 答题次数
          // this.ruleForm.middleParse = res.data.eduExam.middleParse; //答题中查看解析
          // this.ruleForm.afterParse = res.data.eduExam.afterParse; // 交卷后查看解析
          this.ruleForm.passScore = res.data.eduExam.passScore; // 及格分数
          this.ruleForm.totalScore = res.data.eduExam.totalScore; // 总分数
          this.ruleForm.relevanceId = res.data.eduExam.relevanceId; // 指定题库
          this.ruleForm.Trainingtype = res.data.eduExam.categoryName; // 培训类型
          if (res.data.eduExam.trainTypeId) {
            this.params.trainFormId = res.data.eduExam.trainTypeId;
          }
          if (res.data.eduExam.postId) {
            this.params.postFormId = res.data.eduExam.postId;
          }
          if (res.data.eduExam.industryId) {
            this.params.industryFormId = res.data.eduExam.industryId;
          }

          if (res.data.eduExam.occupationId) {
            this.params.occFormId = res.data.eduExam.occupationId;
          }
          if (res.data.eduExam.trainLevelId) {
            this.params.levelFormId = res.data.eduExam.trainLevelId;
          }
          // 考试设置
          for (const key in res.data.eduExamConfig) {
            if (res.data.eduExamConfig[key].configKey == "MIDDLE_PARSE") {
              this.ruleForm.middleParse =
                res.data.eduExamConfig[key].configState;
            }
            if (res.data.eduExamConfig[key].configKey == "AFTER_PARSE") {
              this.ruleForm.afterParse =
                res.data.eduExamConfig[key].configState;
            }
            if (
              res.data.eduExamConfig[key].configKey == "EXAM_AUTHENTICATION"
            ) {
              this.ruleForm.examAuthentication =
                res.data.eduExamConfig[key].configState;
              this.ruleForm.examAuthenticationCopy =
                res.data.eduExamConfig[key].configState;
            }
            if (res.data.eduExamConfig[key].configKey == "ANSWER_NUM") {
              this.ruleForm.answerNum = res.data.eduExamConfig[key].configState;
              if (res.data.eduExamConfig[key].configOther != "{}") {
                let obj = JSON.parse(res.data.eduExamConfig[key].configOther);
                this.answerNumValue = obj.answerNumValue;
              }
            }
            if (res.data.eduExamConfig[key].configKey == "HANDOVER_TIME") {
              this.ruleForm.handoverTime =
                res.data.eduExamConfig[key].configState;
              if (res.data.eduExamConfig[key].configOther != "{}") {
                let obj = JSON.parse(res.data.eduExamConfig[key].configOther);
                this.handoverTimeValue = obj.handoverTimeValue;
              }
            }
          }
          for (const key in res.data.eduExamQuestionConfigs) {
            if (res.data.eduExamQuestionConfigs[key].questionType == "0") {
              this.ruleForm.paperObj.SinglechoiceNum =
                res.data.eduExamQuestionConfigs[key].questionNum;
              this.ruleForm.paperObj.SinglechoiceScore =
                res.data.eduExamQuestionConfigs[key].questionScore;
            }
            if (res.data.eduExamQuestionConfigs[key].questionType == "1") {
              this.ruleForm.paperObj.MultiplechoiceNum =
                res.data.eduExamQuestionConfigs[key].questionNum;
              this.ruleForm.paperObj.MultiplechoiceScore =
                res.data.eduExamQuestionConfigs[key].questionScore;
            }
            if (res.data.eduExamQuestionConfigs[key].questionType == "2") {
              this.ruleForm.paperObj.judgeNum =
                res.data.eduExamQuestionConfigs[key].questionNum;
              this.ruleForm.paperObj.judgeScore =
                res.data.eduExamQuestionConfigs[key].questionScore;
            }
            if (res.data.eduExamQuestionConfigs[key].questionType == "3") {
              this.ruleForm.paperObj.fillNum =
                res.data.eduExamQuestionConfigs[key].questionNum;
              this.ruleForm.paperObj.fillScore =
                res.data.eduExamQuestionConfigs[key].questionScore;
            }
          }
        }
        this.questionbankChange(res.data.eduExam.relevanceId); // 查题库中的题目数量
      });
    },

    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 指定题库
    getquestionBank(query) {
      if (query) {
        this.questionbankList = [];
        this.$post("/special/exam/question/bank/list", {
          questionBankName: query,
        }).then((res) => {
          ;
          this.questionbankList = res.data;
        });
      }
    },
    questionbankChange(val) {
      this.questionbankList = [];
      this.$post("/special/exam/question/bank/list", {
        questionBankId: val,
      }).then((res) => {
        // ;
        this.questionbankList = res.data;
        let item = this.questionbankList.find((el) => el.questionBankId == val);
        this.choiceNum = item.choiceNum;
        this.judgeNum = item.judgeNum;
        this.multiselectNum = item.multiselectNum;
        this.completionNum = item.completionNum;
        this.choiceNumMax = item.choiceNum;
        this.judgeNumMax = item.judgeNum;
        this.multiselectNumMax = item.multiselectNum;
        this.completionNumMax = item.completionNum;
      });
    },
    /**
     * 返回
     */
    dohandleOk() {
      this.$router.push({
        path: "/web/specialTestList",
      });
    },
  },
};
</script>
<style lang="less">
.label_title {
  // display: flex;
  border-left: 7px solid rgb(92, 107, 232);
  padding: 0;
  line-height: 20px;
  font-size: 20px;
}
</style>
